<template>
	<a
		v-if="!!authorName"
		:href="designerProfileLink"
	>
		<div class="designer-ribbon">
			🎨
			<p class="designer-ribbon__author-name">
				Template made by
				<span>{{ authorName }}</span>
			</p>
		</div>
	</a>
</template>

<script>
import {
	mapGetters,
	mapState,
} from 'vuex';

export default {
	computed: {
		...mapGetters(['websiteMeta']),
		...mapState(['domain']),
		designerProfileLink() {
			return this.websiteMeta?.curatedTemplate?.link;
		},
		authorName() {
			return this.websiteMeta?.curatedTemplate?.author;
		},
	},
};
</script>

<style lang="scss" scoped>
.designer-ribbon {
	position: fixed;
	bottom: 16px;
	left: 19px;
	z-index: 100;
	display: flex;
	align-items: center;
	padding: 12px 17px;
	background: rgba($grey-200, 0.7);
	border-radius: 5px;
	backdrop-filter: blur(6px);

	&__icon {
		width: 14px;
		height: 20px;
		margin-right: 10px;
	}

	&__author-name {
		font-size: 14px;
		font-weight: normal;
		line-height: 1.43;
		text-transform: none;
		letter-spacing: 0;
	}
}
</style>
