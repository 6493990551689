<template>
	<div>
		<BuilderHeaderPreview
			class="page-layout__header page-layout__header--sticky"
			@exit-preview="redirectToEditor"
		>
			<template
				v-if="isBlogEditMode"
				#title
			>
				<div class="blog-header">
					<h3 class="z-body z-body--strong">
						{{ currentPage.meta.title || currentPage.name }} ·
						<span class="z-body">
							{{ currentPage.customData.hidden ? $t('common.draft') : $t('common.published') }}
						</span>
					</h3>
				</div>
			</template>
		</BuilderHeaderPreview>
		<BuilderMobileFrame show-mockup>
			<Page
				:hide-ribbons="isUserDesigner"
				is-preview-mode
				:preview-page-id="currentPageId"
				:is-mobile-view="isMobileView"
				:preview-blocks="currentPage.blocks"
			/>
		</BuilderMobileFrame>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapActions,
} from 'vuex';

import BuilderMobileFrame from '@/components/builder-view/components/BuilderMobileFrame.vue';
import BuilderHeaderPreview from '@/components/builder-view/headers/BuilderHeaderPreview.vue';
import {
	BLOG_ROUTE,
	BUILDER_ROUTE,
} from '@/router';
import Page from '@user/components/Page.vue';

export default {
	name: 'Preview',
	components: {
		Page,
		BuilderHeaderPreview,
		BuilderMobileFrame,
	},
	data() {
		return { pageIdBeforeEnter: null };
	},
	computed: {
		...mapState([
			'website',
			'hasActivePlan',
		]),
		...mapState('blog', ['isBlogEditMode']),
		...mapState('gui', ['isMobileView']),
		...mapState('pages', ['currentPageId']),
		...mapState('user', ['isUserDesigner']),
		...mapGetters('pages', ['currentPage']),
	},
	mounted() {
		this.pageIdBeforeEnter = this.currentPageId;
	},
	methods: {
		...mapActions('pages', ['setCurrentPage']),
		redirectToEditor() {
			if (this.isBlogEditMode) {
				this.$router.push({
					name: BLOG_ROUTE,
					params: { postId: this.pageIdBeforeEnter },
				});

				return;
			}

			this.$router.push({ name: BUILDER_ROUTE });
		},
	},
};
</script>
<style lang="scss" scoped>
@import './PageLayout';

.blog-header {
	display: flex;
	flex-direction: row;

	&__title {
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: 0.03em;

		&--regular {
			font-weight: normal;
		}
	}
}
</style>
