<template>
	<main
		v-if="website"
		ref="pageRef"
		class="page"
		:style="siteCSSVariables"
	>
		<div
			ref="stickyTriggerRef"
			class="sticky-trigger"
			:style="{ top: `${-builderHeaderOffset}px` }"
		/>
		<ZyroRibbonUser
			v-if="showRibbons"
			ref="ribbonRef"
			:href="ribbonHref"
			:style="{ top: `${builderHeaderOffset}px` }"
		/>
		<BlockHeader
			v-if="!website.navigation.hidden"
			id="navigation"
			:is-preview-navigation="isPreviewMode"
			:data="website.blocks.navigation"
			:has-user-scrolled="hasUserScrolled"
			:style="{ top: `${builderHeaderOffset + ribbonHeight}px` }"
		/>
		<!-- Simple blocks -->
		<BlockUser
			v-for="(blockId, index) of allBlocks"
			:id="blockId"
			:key="blockId"
			:data="website.blocks[blockId]"
			:blocks="website.blocks"
			:components="website.components"
			:is-next-to-transparent-header="index === 0 && website.blocks.navigation.background.isTransparent"
			:is-preview-mode="isPreviewMode"
			:is-mobile-view="isMobileView"
		/>
		<!-- Curated Designer Badge (it's not a ribbon) -->
		<CuratedDesignerRibbon v-if="showRibbons" />
	</main>
</template>

<script>
import { mapState } from 'vuex';

import { useSiteStyles } from '@/use/useSiteStyles';
import BlockHeader from '@user/components/block/BlockHeader.vue';
import BlockUser from '@user/components/block/BlockUser.vue';
import CuratedDesignerRibbon from '@user/components/ui/CuratedDesignerRibbon.vue';
import ZyroRibbonUser from '@user/components/ui/ZyroRibbonUser.vue';

const BUILDER_HEADER_HEIGHT = 50;

export default {
	name: 'Page',
	components: {
		BlockUser,
		BlockHeader,
		/* Global components are not registered in user app, so that has to be done manually */
		ZyroRibbonUser,
		CuratedDesignerRibbon,
	},
	props: {
		isPreviewMode: {
			type: Boolean,
			default: false,
		},
		isMobileView: {
			type: Boolean,
			default: false,
		},
		previewBlocks: {
			type: Array,
			default: () => ([]),
		},
		previewPageId: {
			type: String,
			default: '',
		},
		hideRibbons: {
			type: Boolean,
			required: false,
		},
	},
	setup() {
		const { siteCSSVariables } = useSiteStyles();

		return { siteCSSVariables };
	},
	data() {
		return {
			stickyIntersectionObserver: null,
			ribbonResizeObserver: null,
			hasUserScrolled: false,
			ribbonHeight: 0,
		};
	},
	computed: {
		...mapState([
			'website',
			'hasActivePlan',
		]),
		...mapState(['user']),
		...mapState('user', ['isUserDesigner']),
		currentPage({ website }) {
			return this.isPreviewMode
				? this.$store.getters['pages/currentPage']
				: Object.values(website.pages).find(({ path }) => path === this.$route.path);
		},
		pageBlocks() {
			if (this.isPreviewMode) {
				return this.previewBlocks;
			}

			return this.currentPage.blocks;
		},
		pageBlocksSlotFooter() {
			if (this.currentPage.footerSlotIsHidden) {
				return [];
			}

			const footerBlock = Object.keys(this.website.blocks)
				.find((blockId) => this.website.blocks[blockId].slot === 'footer');

			return footerBlock ? [footerBlock] : [];
		},
		allBlocks() {
			return [
				...this.pageBlocks,
				...this.pageBlocksSlotFooter,
			];
		},
		ribbonHref() {
			return `https://zyro.com/templates?utm_source=zyro&utm_medium=free-site&utm_campaign=
						${document.location.host}&utm_content=header-zyro-ribbon`;
		},
		showRibbons() {
			return !this.hideRibbons && !this.hasActivePlan;
		},
		builderHeaderOffset() {
			return (this.isPreviewMode && !this.isMobileView) ? BUILDER_HEADER_HEIGHT : 0;
		},
	},
	watch: {
		async website(newValue, oldValue) {
			if (newValue && !oldValue) {
				await this.$nextTick();
				this.initObservers();
			}
		},
	},
	mounted() {
		this.initObservers();
	},
	beforeDestroy() {
		if (this.stickyIntersectionObserver) {
			this.stickyIntersectionObserver.disconnect();
		}

		if (this.ribbonResizeObserver) {
			this.ribbonResizeObserver.disconnect();
		}
	},
	methods: {
		initObservers() {
			if (this.$refs.stickyTriggerRef && !this.stickyIntersectionObserver) {
				this.stickyIntersectionObserver = new IntersectionObserver(([{ isIntersecting }]) => {
					this.hasUserScrolled = !isIntersecting;
				}, {
					// use either mobile frame or null (document)
					root: this.isMobileView ? this.$refs.pageRef.parentElement : null,
					threshold: 1,
				});

				this.stickyIntersectionObserver.observe(this.$refs.stickyTriggerRef);
			}

			if (this.showRibbons && this.$refs.ribbonRef && !this.ribbonResizeObserver) {
				this.ribbonResizeObserver = new ResizeObserver(([firstEntry]) => {
					this.ribbonHeight = firstEntry.borderBoxSize[0].blockSize;
				});

				this.ribbonResizeObserver.observe(this.$refs.ribbonRef.$el);
			}
		},
	},
};
</script>

<style lang="scss">
.page {
	// overflow-x: hidden;
}

.sticky-trigger {
	position: relative;
	z-index: -1;
	height: 1px;
	margin-bottom: -1px;
	pointer-events: none;
}
</style>
